<script lang="ts" module>
	export const computeSizeClasses = (
		size: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
	): [number, number, string] => {
		let width = 48
		let height = 48
		let sizeClasses = 'w-12 h-12'

		switch (size) {
			case 'xs':
				width = 28
				height = 28
				sizeClasses = 'w-7 h-7'
				break
			case 'sm':
				width = 32
				height = 32
				sizeClasses = 'w-8 h-8'
				break
			case 'md':
				width = 40
				height = 40
				sizeClasses = 'w-11 h-11'
				break
			case 'lg':
				width = 48
				height = 48
				sizeClasses = 'w-12 h-12'
				break
			case 'xl':
				width = 96
				height = 96
				sizeClasses = 'w-24 h-24'
				break
			default:
				width = 48
				height = 48
				sizeClasses = 'w-12 h-12'
				break
		}

		return [width, height, sizeClasses]
	}
</script>

<script lang="ts">
	import { cdnSrc } from '$lib/utils/cloudflare-loader'

	interface Props {
		size: 'sm' | 'md' | 'lg' | 'xl'
		src: string
		alt: string
	}

	let { size, src = $bindable(), alt }: Props = $props()
	const [width, height, sizeClasses] = computeSizeClasses(size)
	let computedSrc = $derived(cdnSrc(src, width))

	function handleImageLoadError() {
		src = '/visuals/icons/default-user.svg'
	}
</script>

<span class={`${sizeClasses} inline-block m-0 overflow-visible bg-white border-2 rounded-full`}>
	<img {src} class="rounded-full" {alt} {width} {height} onerror={handleImageLoadError} />
</span>
